const merchantTabNameEnums = {
  MERCHANT_ROW: "MerchantRow",
  MORDOR_PRODUCTS: "MordorProducts",
  ADVANTAGE_PRODUCTS: "AdvantageProducts",
  ABOUT_SELLER: "AboutSeller",
  SUPER_TAB: "SuperTab"
};
const queryTabNameEnums = {
  ALL_PRODUCTS: "allproducts",
  ADVANTAGE_PRODUCTS: "advantageproducts",
  PROFILE: "profile",
  STORE: "store",
  SUPER_TAB: "kesfet"
};
const QUERY_TO_TABS = {
  [queryTabNameEnums.STORE]: merchantTabNameEnums.MERCHANT_ROW,
  [queryTabNameEnums.ALL_PRODUCTS]: merchantTabNameEnums.MORDOR_PRODUCTS,
  [queryTabNameEnums.ADVANTAGE_PRODUCTS]: merchantTabNameEnums.ADVANTAGE_PRODUCTS,
  [queryTabNameEnums.PROFILE]: merchantTabNameEnums.ABOUT_SELLER,
  [queryTabNameEnums.SUPER_TAB]: merchantTabNameEnums.SUPER_TAB
};
const TABS_TO_QUERY = {
  [merchantTabNameEnums.MERCHANT_ROW]: queryTabNameEnums.STORE,
  [merchantTabNameEnums.MORDOR_PRODUCTS]: queryTabNameEnums.ALL_PRODUCTS,
  [merchantTabNameEnums.ADVANTAGE_PRODUCTS]: queryTabNameEnums.ADVANTAGE_PRODUCTS,
  [merchantTabNameEnums.ABOUT_SELLER]: queryTabNameEnums.PROFILE,
  [merchantTabNameEnums.SUPER_TAB]: queryTabNameEnums.SUPER_TAB
};

const merchantTabLabelEnums = {
  MERCHANT_ROW: "Mağaza",
  MORDOR_PRODUCTS: "Tüm ürünler",
  ADVANTAGE_PRODUCTS: "Avantajlı ürünler",
  ABOUT_SELLER: "Satıcı profili",
  SUPER_TAB: "Keşfet"
};

const merchantTabs = [
  {
    name: merchantTabNameEnums.MERCHANT_ROW,
    label: merchantTabLabelEnums.MERCHANT_ROW
  },
  {
    name: merchantTabNameEnums.SUPER_TAB,
    label: merchantTabLabelEnums.SUPER_TAB
  },
  {
    name: merchantTabNameEnums.ADVANTAGE_PRODUCTS,
    label: merchantTabLabelEnums.ADVANTAGE_PRODUCTS
  },
  {
    name: merchantTabNameEnums.MORDOR_PRODUCTS,
    label: merchantTabLabelEnums.MORDOR_PRODUCTS
  },
  {
    name: merchantTabNameEnums.ABOUT_SELLER,
    label: merchantTabLabelEnums.ABOUT_SELLER
  }
];

export { merchantTabNameEnums, QUERY_TO_TABS, TABS_TO_QUERY, merchantTabLabelEnums, queryTabNameEnums, merchantTabs };
